import * as ToastPrimitive from '@radix-ui/react-toast';
import { motion } from 'framer-motion';
import { FC } from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import Icon from '~/components/Icon';

interface ToastStore {
  isOpen: boolean;
  title?: string;
  description?: string;
  variant: 'success' | 'error';
  trigger: (
    title: string,
    description: string,
    variant?: 'success' | 'error',
  ) => void;
  close: (isOpen: boolean) => void;
}

const INITIAL_STATE: Pick<
  ToastStore,
  'isOpen' | 'title' | 'description' | 'variant'
> = {
  isOpen: false,
  title: undefined,
  description: undefined,
  variant: 'success',
};
const useToast = create<ToastStore>((set) => ({
  ...INITIAL_STATE,
  trigger: (title, description, variant) =>
    set(() => ({
      isOpen: true,
      title,
      description,
      variant: variant ?? INITIAL_STATE.variant,
    })),
  close: () => set(() => INITIAL_STATE),
}));

/**
 * @example
 * const triggerToast = useToastTrigger();
 * useEffect(() => {
 *   if (submitSuccessful) {
 *     triggerToast('Kunde angelegt', 'Kunde wurde erfolgreich angelegt.');
 *   }
 * }, [submitSuccessful, triggerToast]);
 */
export const useToastTrigger = () => useToast((state) => state.trigger);

/**
 * There should only ever be one instance of this toast in `root.tsx`. If you
 * want to trigger the toast, use `useToastTrigger` above:
 */
const Toast: FC = () => {
  const [isOpen, title, description, variant, close] = useToast(
    (state) => [
      state.isOpen,
      state.title,
      state.description,
      state.variant,
      state.close,
    ],
    shallow,
  );

  return (
    <div className="z-10">
      <ToastPrimitive.Provider>
        <ToastPrimitive.Root
          open={isOpen}
          onOpenChange={close}
          className="min-w-[10rem] max-w-[24rem] rounded bg-white px-4 py-2 shadow-lg"
          asChild
        >
          <motion.div initial={{ x: 500 }} animate={{ x: 0 }}>
            <ToastPrimitive.Title className="mb-1 flex items-center gap-2 text-lg font-bold">
              <Icon
                id={
                  variant === 'success'
                    ? 'check-circle-solid'
                    : 'exclamation-circle'
                }
                className={
                  variant === 'success' ? 'text-lime-500' : 'text-red-600'
                }
              />
              {title}
            </ToastPrimitive.Title>
            <ToastPrimitive.Description>
              {description}
            </ToastPrimitive.Description>
            <ToastPrimitive.Close asChild>
              <button
                type="button"
                aria-label="Toast schließen"
                className="absolute right-1 top-1 h-7 w-7 rounded-full p-1 transition-colors hover:bg-gray-200 focus:bg-gray-200"
              >
                <Icon id="x-solid" />
              </button>
            </ToastPrimitive.Close>
          </motion.div>
        </ToastPrimitive.Root>

        <ToastPrimitive.Viewport className="fixed top-20 right-4 flex flex-col" />
      </ToastPrimitive.Provider>
    </div>
  );
};

export default Toast;
